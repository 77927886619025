<script setup>
import ProductPreview from "@/components/molecules/ProductPreview.vue";
import Button from "~/components/atoms/Button.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";
import Text from "~/components/atoms/Text.vue";
import SortingIcon from "~/components/icons/SortingIcon.vue";
import FilterIcon from "~/components/icons/FilterIcon.vue";

const props = defineProps({
  products: Array,
  chosenFilters: Object,
  total: Number,
  currentSorting: String,
  noHeader: {
    type: Boolean,
    default: false
  },
})

const products = ref(props.products)

watch(() => props.products, (newVal) => {
  products.value = newVal;
});
</script>

<template>
  <div class="g-24">
    <template v-if="!noHeader">
      <div class="row full-width header">
        <div class="row g-32 btn-block">
          <Button @click="$emit('filter')" class="reset middle uppercase full-width" type="black">
            <FilterIcon width="20px" height="18px"/>
            &nbsp;
            {{ $t('base.filter_action') }}
          </Button>
          <Text v-if="$device.isDesktop" tag="b" class="counter lowercase">{{ Math.round(total / 10) * 10 }}+ {{
              $t('product.products')
            }}
          </Text>
        </div>

        <div class="sorting">
          <div class="no-flex row g-2">
            <SortingIcon class="sorting-icon" style="stroke: black;"/>
            <select class="invisible-select"
                    @change="$emit('sortingChanged',$event.currentTarget.value)">
              <option value="recommended" :selected="currentSorting === 'recommended'">
                {{ $t('product.recommended') }}
              </option>
              <option value="bestseller" :selected="currentSorting === 'bestseller'">
                {{ $t('product.bestseller') }}
              </option>
              <option value="new" :selected="currentSorting === 'new'">
                {{ $t('product.new') }}
              </option>
              <option value="from-cheap" :selected="currentSorting === 'from-cheap'">
                $ - $$$
              </option>
              <option value="from-expensive" :selected="currentSorting === 'from-expensive'">
                $$$ - $$
              </option>
              <option value="random" :selected="currentSorting === 'random'">
                {{ $t('product.random') }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="filter-labels row g-32 wrap" v-if="Object.values(props.chosenFilters ?? {}).length">
        <div class="row g-24 wrap">
          <template v-for="(filterValue) in Object.entries(props.chosenFilters)">
            <Button @click="$emit('removeFilter',{
          name:filterValue[0],
          value:item
        })" class="filter-label uppercase wrap" type="mini-label" v-for="item in filterValue[1]">
              <div class="row g-4">{{ item }}
                <CrossIcon/>
              </div>
            </Button>
          </template>
        </div>
        <Text class="clickable clear" @click="$emit('clearFilter')" underline>{{ $t("base.reset_filters") }}</Text>
      </div>
    </template>

    <div class="products-grid">
      <template :key="product.sku" v-for="(product,key) in products">
        <product-preview
            :sku="product.sku"
            :adminInfo="product.admin"
            :price="product.main_price"
            :discount="product.discount"
            :status="product['Lager Status_attr']"
            :old-price="product.hc_recommended_price"
            :unit="product.unit"
            :restparti_qty="product.restparti_qty"
            :restparti_unit_block="product.restparti_unit_block"
            :labels="product.labels"
            :name="product.display_name"
            :second-image="product.second_image"
            :mobile-image="product.mobile_image"
            :slug="product.url"
            :another-products="product.anotherColors"
            :lazy="key > 7"
            :main-image="product.image"/>
      </template>
    </div>
  </div>
</template>


<style scoped lang="scss">
.header {
  width: 100%;
  justify-content: space-between;

  @include smd {
    gap: 32px;
  }

  .sorting, .btn-block {
    flex: unset;

    @include smd {
      flex: 1;
    }

    button {
      width: 165px;

      @include smd {
        width: 100%;
      }
    }
  }
}

.clear {
  font-size: 13px;
  color: $bb;
}

.sorting {
  @include smd {
    border: 1px solid #000;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 148px;
  }
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 32px 22px;

  @include smd {
    grid-template-columns: repeat(3, 3fr);
    column-gap: 10px;
  }

  @include mobile {
    grid-template-columns: repeat(2, 2fr);
    column-gap: 10px;
    margin: 0 -16px;
  }
}

.invisible-select {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  border: 0;
  outline: none;
  background: $white;
  text-transform: uppercase;
  letter-spacing: .78px;
  color: $black;

  @include smd {
    font-size: 9px;
  }
}

.sorting-icon {
  @include smd {
    height: 8px;
  }
}

.counter {
  font-size: 12px;
  text-wrap: nowrap;
}
</style>
