<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
    <path id="Vector 69" d="M0.800781 1H15.2008M0.800781 5H10.4008M0.800781 9H5.60078"/>
  </svg>
</template>

<script>
export default {
  name: 'SortingIcon'
}
</script>
